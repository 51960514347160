import React, { useEffect, useContext } from "react";
import styled from 'styled-components';
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";
import { setBodyOverflow } from "../../services/browser/browser";
import SocialMedia from "../Core/SocialMedia";
import ThemeContext from "../../services/themes/themeContext";
import { Link } from "gatsby"

const NavContainer = styled.div`
  background: ${props => props.islight ? colors.lightGrey : colors.darkGrey};
  height: 100vh;
  width: 100vw;
  transition: .4s;
  position: fixed;
  top: ${props => props.open ? "0" : "-100vh"};
  z-index: 998;
  
  @media(min-width: ${breakpoints.md}){
    display: none;
  }
`;

const NavContent = styled.div`
  opacity: ${props => props.open ? "1" : "0"};
  transition: .6s;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LinkStyled = styled(Link)`
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.72px;
  ${fonts.CabinRegular};
  text-transform: uppercase;
  text-decoration: none;
  color: ${props => props.islight ? colors.navy : colors.lightGrey};
  padding: 14px 0;
  
  :last-of-type {
    color: ${colors.red};
  }
`;

const SocialMediaContainer = styled.div`
  padding: 40px 0 0 16px;
`;

const MobileNav = ({ setIsMobileNavOpen, isMobileNavOpen, links }) => {
  const {isLight} = useContext(ThemeContext);

  useEffect(() => {
    if (isMobileNavOpen === true) {
      setBodyOverflow(true)
    } else {
      setBodyOverflow(false)
    }
  }, [isMobileNavOpen])

  return (
    <NavContainer open={isMobileNavOpen} islight={isLight ? 1 : 0}>
      <NavContent open={isMobileNavOpen} onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}>
        {links.map((link, index) => {
          return (
            <LinkStyled to={link.ctaUrl} key={index} islight={isLight ? 1 : 0}>
              {link.ctaLabel}
            </LinkStyled>
          )
        })}
        <SocialMediaContainer>
          <SocialMedia/>
        </SocialMediaContainer>
      </NavContent>
    </NavContainer>
  )
};

export default MobileNav;