import React from "react";
import styled from "styled-components";
import colors from "../../styles/colors";
import LightLogo from "../../resources/images/ui/light-logo.svg";
import DarkLogo from "../../resources/images/ui/dark-logo.svg";

const LoaderContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  background: ${props => props.isLight ? colors.lightGrey : colors.darkGrey};
  z-index: 9999;
`;

const Logo = styled.img`
  width: 40vw;
  max-width: 600px;
`;

const Loader = ({isLight}) => {
  return (
    <LoaderContainer isLight={isLight}>
      <Logo src={isLight ? DarkLogo : LightLogo} alt={""} />
    </LoaderContainer>
  )
}

export default Loader
