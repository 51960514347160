import React from "react";
import styled from "styled-components";
import ExternalLink from "./ExternalLink";
import FacebookIcon from "../../resources/images/ui/facebook-icon.svg";
import LinkedInIcon from "../../resources/images/ui/linkedin-icon.svg";
import TwitterIcon from "../../resources/images/ui/twitter-icon.svg";

const SocialMediaContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const SocialLink = styled(ExternalLink)`
  height: 24px;
  width: 24px;
  margin: 0 16px 0 0;
`;

const SocialMedia = () => {
  const socialMedia = [
    {
      url: "https://www.facebook.com/L4G.dev/",
      alt: "Facebook",
      icon: FacebookIcon
    },
    {
      url: "https://www.linkedin.com/company/l4g-dev/",
      alt: "LinkedIn",
      icon: LinkedInIcon
    },
    {
      url: "https://twitter.com/L4G_dev",
      alt: "Twitter",
      icon: TwitterIcon
    },
  ]

  return (
    <SocialMediaContainer>
      {socialMedia.map((link, index) => {
        return (
          <SocialLink href={link.url} key={index}>
            <img src={link.icon} alt={link.alt}/>
          </SocialLink>
        )
      })}
    </SocialMediaContainer>
  )
}

export default SocialMedia;