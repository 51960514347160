import React, { useContext } from "react";
import ContentContainer from "../Core/ContentContainer";
import { Row, Col } from "styled-bootstrap-grid";
import styled from "styled-components";
import colors from "../../styles/colors";
import breakpoints from "../../styles/breakpoints";
import fonts from "../../styles/fonts";
import LogoLight from "../../resources/images/ui/logo-light.svg";
import LogoDark from "../../resources/images/ui/logo-dark.svg";
import SocialMedia from "../Core/SocialMedia";
import Link from "gatsby-link";
import { graphql, StaticQuery } from "gatsby";
import ExternalLink from "../Core/ExternalLink";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import ThemeContext from "../../services/themes/themeContext";

const FooterContainer = styled.div`
  padding: 40px 0 40px 0;
  background: ${props => props.theme.isLight ? colors.lightGrey : colors.darkGrey};

  @media(min-width: ${breakpoints.md}){
    padding: 40px 0 80px 0;
  }
`;

const FooterRow = styled.div`
  max-width: 604px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media(min-width: ${breakpoints.sm}){
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end
  }
`;

const LogoLink = styled(Link)`
  display: block;
  margin-bottom: 12px;
`;

const SocialMediaContainer = styled.div`
  margin-top: 12px;
`;

const CopyRight = styled.div`
  padding: 40px 15px 0;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.48px;
  ${fonts.CabinRegular};
  text-align: center;
  color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
  border-top: 1px solid ${colors.red};
`;

const FooterBlock = styled.div`
  margin-bottom: 40px;
`;

const Text = styled.div`
  color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
  ${fonts.CabinRegular};

  p, a {
    color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.48px;
    text-decoration: none;
    display: block;
  }
  
  a {
    transition: .2s;
  }
  
  a:hover {
    color: ${colors.red};
    transition: .2s;
  }

  @media(min-width: ${breakpoints.md}){
    p, a {
      line-height: 24px;
    }
  }
`;

const Links = styled.div`
  margin: -8px 0;

  @media(min-width: ${breakpoints.md}){
    margin: 0;
  }
`

const LinkStyled = styled(Link)`
  display: block;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.64px;
  text-decoration: none;
  ${fonts.CabinRegular};
  text-transform: uppercase;
  color: ${props => props.theme.isLight ? colors.darkGrey : colors.lightGrey};
  padding: 8px 0;
  transition: .2s;

  :hover {
    color: ${colors.red};
    transition: .2s;
  }

  @media(min-width: ${breakpoints.md}){
    padding: 16px 0 0 0;
  }
`;

const ExternalLinkStyled = styled(ExternalLink)`
  max-width: 154px;
`;

const Footer = () => {
  const {isLight} = useContext(ThemeContext);
  return (
  <StaticQuery
    query={graphql`
      query {
        linksContent: contentfulHeader {
          links {
            ctaUrl
            ctaLabel
          }
        }
        footerContent: contentfulFooter {
          link {
            ctaUrl
            ctaLabel
          }
          shortDescription {
            raw
          }
          copyright
        }
      }
    `}
    render={({ linksContent, footerContent }) => {
      const links = linksContent.links;

      return (
        <FooterContainer>
          <ContentContainer>
            <Row>
              <Col xs={10} sm={10} md={10} xsOffset={1} smOffset={1} mdOffset={1}>
                <FooterRow>
                  <FooterBlock>
                    <LogoLink to={"/"}>
                      <img src={isLight ? LogoDark : LogoLight} alt={"Logo"}/>
                    </LogoLink>
                    <Text>
                      {documentToReactComponents(JSON.parse(footerContent.shortDescription.raw))}
                    </Text>
                    <SocialMediaContainer>
                      <SocialMedia/>
                    </SocialMediaContainer>
                  </FooterBlock>
                  <FooterBlock>
                    <Text>
                      {footerContent.link.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            {item.ctaLabel === "Telephone" && item.ctaUrl !== null && <ExternalLink href={"tel:" + item.ctaUrl}>{item.ctaLabel} : {item.ctaUrl}</ExternalLink>}
                            {item.ctaLabel === "Email" && item.ctaUrl !== null && <ExternalLink href={"mailto:"+ item.ctaUrl}>{item.ctaLabel} : {item.ctaUrl}</ExternalLink>}
                            {((item.ctaLabel !== "Email") && (item.ctaLabel !== "Telephone")) && <ExternalLinkStyled href={item.ctaUrl}>{item.ctaLabel}</ExternalLinkStyled>}
                          </React.Fragment>
                        )
                      })}
                    </Text>
                  </FooterBlock>
                  <FooterBlock>
                    <Links>
                      {links.map((link, index) => {
                        return (
                          <LinkStyled to={link.ctaUrl} key={index}>{link.ctaLabel}</LinkStyled>
                        )
                      })}
                      <LinkStyled to="/privacy-policy">Privacy policy</LinkStyled>
                    </Links>
                  </FooterBlock>
                </FooterRow>
              </Col>
              <Col md={10} mdOffset={1}>
                <CopyRight>{footerContent.copyright}</CopyRight>
              </Col>
            </Row>
          </ContentContainer>
        </FooterContainer>
      )
    }}
  />
  );
}

export default Footer;
