import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import {
  CabinBoldWoff2, CabinRegularWoff2, LagiWoff2, RalewayMediumWoff2, RalewayRegularWoff2, RalewayThinWoff2
} from "../../styles/fontsLoader"
import favicon from "../../resources/images/favicon/favicon.png"

function Seo({ description, lang, meta, title, path }) {
  const { site } = useStaticQuery(
    graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    author
                }
            }
        }
    `
  )

  const websiteUrl = process.env.WEBSITE_URL
  const metaDescription = description || site.siteMetadata.description
  const url = websiteUrl + path
  const pageUrl = url.substr(-1) === "/" ? url.substr(0, url.length - 1) : url
  const socialSharingImage = websiteUrl + favicon

  const fontLink = (font) => {
    return <link rel={'preload'} as={'font'} href={font} type={'font/woff2'} crossOrigin="anonymous"/>
  }
  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:url`,
          content: pageUrl
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:image`,
          content: socialSharingImage
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ].concat(meta)}
    >
      {fontLink(CabinRegularWoff2)}
      {fontLink(CabinBoldWoff2)}
      {fontLink(RalewayThinWoff2)}
      {fontLink(RalewayRegularWoff2)}
      {fontLink(RalewayMediumWoff2)}
      {fontLink(LagiWoff2)}
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
}

export default Seo
