function isServer() {
  return typeof window === 'undefined';
}

function getItem(key) {
  if (isServer()) {
    return;
  }

  return localStorage.getItem(key);
}

function setItem(key, data) {
  if (isServer()) {
    return;
  }

  localStorage.setItem(key, data);
}

function removeItem(key) {
  if (isServer()) {
    return;
  }

  localStorage.removeItem(key);
}

module.exports = {
  getItem: getItem,
  setItem: setItem,
  removeItem: removeItem
};
