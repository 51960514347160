import React, { useState } from "react"
import { isBrowser } from "../browser/browser"

const ThemeContext = React.createContext({})

export const useDarkMode = () => {
  const [mountedComponent, setMountedComponent] = useState(false)
  let localTheme = 'dark'
  if (isBrowser()) {
    localTheme = window.localStorage.getItem("theme")

    if (mountedComponent === false && localTheme === null) {
      localTheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches ?
        'light' : 'dark'
      window.localStorage.setItem("theme", localTheme)
      setMountedComponent(true)
    }
  }

  const [theme, setTheme] = useState(localTheme)

  const setMode = mode => {
    window.localStorage.setItem("theme", mode)
    setTheme(mode)
  }

  const toggleTheme = () => {
    theme === "light" ? setMode("dark") : setMode("light")
  }

  return [theme, toggleTheme, mountedComponent];
}

export default ThemeContext;
