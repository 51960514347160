const colors = {
  white: '#FFFFFF',
  grey: '#F5F5FB',
  lightGrey: '#F1F1F6',
  pink: '#EC849E',
  red: '#A20029',
  darkRed: '#940022',
  lilac: '#6970A1',
  navy: '#211E2C',
  mediumNavy: '#202231',
  darkGrey: '#1D1F2D',
  black: '#000000',
};

export default colors;
