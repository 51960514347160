import React from "react";
import { Link } from "gatsby";
import styled from 'styled-components';
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";
import ExternalLink from "./ExternalLink";

const LinkStyled = styled(Link)`
  font-size: ${props => props.large ? "20px" : "18px"};
  line-height: 22px;
  letter-spacing: 0.72px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  min-width: ${props => props.large ? "180px" : "max-content"};
  width: max-content;
  color: ${props => props.isred ? colors.red : props.theme.isLight ? colors.navy : colors.lightGrey};
  ${fonts.CabinRegular};
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  transition: 1s;
  height: 46px;
  border: 1px solid ${props => props.isred ? colors.red : props.theme.isLight ? colors.navy : colors.lightGrey};
  padding: ${props => props.large ? "0 34px" : "0 20px"};

  ::before, ::after {
    position: absolute;
    background: ${props => props.theme.isLight ? colors.lightGrey : colors.navy};
    z-index: -1;
    transition: .2s;
    content: '';
  }

  ::before {
    width: ${props => props.large ? "15px" : "10px"};
    left: 10px;
    height: 1px;
    top: -1px;
  }

  ::after {
    width: 1px;
    height: ${props => props.large ? "15px" : "10px"};
    top: 20px;
    left: -1px;
  }

  :hover::before {
    width: 0;
    background: ${props => props.theme.isLight ? colors.lightGrey : colors.navy};
  }

  :hover::after {
    height: 0;
    background: ${props => props.theme.isLight ? colors.lightGrey : colors.navy};
  }

  :hover {
    background: ${props => props.theme.isLight ? colors.lightGrey : colors.navy};
  }
  
  :hover div {
    width: 0;
    background: ${props => props.theme.isLight ? colors.lightGrey : colors.navy};
  }
  
  @media (min-width: ${breakpoints.md}) {
    font-size: ${props => props.large ? "22px" : "18px"};
    height: ${props => props.large ? "46px" : "38px"};
    padding: ${props => props.large ? "0 40px": "0 50px"};
    border: ${props => props.large ? "2px" : "1px"} solid ${props => props.isred ? colors.red : props.theme.isLight ? colors.navy : colors.lightGrey};

    ::before {
      width: ${props => props.large ? "15px" : "10px"};
      height: ${props => props.large ? "2px" : "1px"};
      top: ${props => props.large ? "-2px" : "-1px"};
    }

    ::after {
      width: ${props => props.large ? "2px" : "1px"};
      height: ${props => props.large ? "15px" : "10px"};
      top: ${props => props.large ? "30px" : "20px"};
      left: ${props => props.large ? "-2px" : "-1px"};
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    font-size: ${props => props.large ? "24px" : "18px"};
    height: ${props => props.large ? "56px" : "38px"};
  };
`;

const ButtonStyled = styled.button`
  font-size: ${props => props.large ? "20px" : "18px"};
  line-height: 22px;
  letter-spacing: 0.72px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  min-width: ${props => props.large ? "180px" : "max-content"};
  width: max-content;
  color: ${props => props.isred ? colors.red : props.theme.isLight ? colors.navy : colors.lightGrey};
  ${fonts.CabinRegular};
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  transition: 1s;
  height: 46px;
  border: 1px solid ${props => props.isred ? colors.red : props.theme.isLight ? colors.navy : colors.lightGrey};
  padding: ${props => props.large ? "0 34px" : "0 20px"};

  ::before, ::after {
    position: absolute;
    background: ${props => props.theme.isLight ? colors.lightGrey : colors.navy};
    z-index: -1;
    transition: .2s;
    content: '';
  }

  ::before {
    width: ${props => props.large ? "15px" : "10px"};
    left: 10px;
    height: 1px;
    top: -1px;
  }

  ::after {
    width: 1px;
    height: ${props => props.large ? "15px" : "10px"};
    top: 20px;
    left: -1px;
  }

  :hover::before {
    width: 0;
    background: ${props => props.theme.isLight ? colors.lightGrey : colors.navy};
  }

  :hover::after {
    height: 0;
    background: ${props => props.theme.isLight ? colors.lightGrey : colors.navy};
  }

  :hover {
    background: ${props => props.theme.isLight ? colors.lightGrey : colors.navy};
  }

  :hover div {
    width: 0;
    background: ${props => props.theme.isLight ? colors.lightGrey : colors.navy};
  }

  @media (min-width: ${breakpoints.md}) {
    font-size: ${props => props.large ? "26px" : "18px"};
    height: ${props => props.large ? "56px" : "38px"};
    padding: ${props => props.large || props.isred ? "0" : "0 50px"};
    border: ${props => props.large ? "2px" : "1px"} solid ${props => props.isred ? colors.red : props.theme.isLight ? colors.navy : colors.lightGrey};

    ::before {
      width: ${props => props.large ? "15px" : "10px"};
      height: ${props => props.large ? "2px" : "1px"};
      top: ${props => props.large ? "-2px" : "-1px"};
    }

    ::after {
      width: ${props => props.large ? "2px" : "1px"};
      height: ${props => props.large ? "15px" : "10px"};
      top: ${props => props.large ? "30px" : "20px"};
      left: ${props => props.large ? "-2px" : "-1px"};
    }
  }
`;

const ExternalLinkStyled = styled(ExternalLink)`
  font-size: ${props => props.large ? "20px" : "18px"};
  line-height: 22px;
  letter-spacing: 0.72px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  min-width: ${props => props.large ? "180px" : "max-content"};
  width: max-content;
  color: ${props => props.isred ? colors.red : props.theme.isLight ? colors.navy : colors.lightGrey};
  ${fonts.CabinRegular};
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  transition: 1s;
  height: 46px;
  border: 1px solid ${props => props.isred ? colors.red : props.theme.isLight ? colors.navy : colors.lightGrey};
  padding: ${props => props.large ? "0 34px" : "0 20px"};

  ::before, ::after {
    position: absolute;
    background: ${props => props.theme.isLight ? colors.lightGrey : colors.navy};
    z-index: -1;
    transition: .2s;
    content: '';
  }

  ::before {
    width: ${props => props.large ? "15px" : "10px"};
    left: 10px;
    height: 1px;
    top: -1px;
  }

  ::after {
    width: 1px;
    height: ${props => props.large ? "15px" : "10px"};
    top: 20px;
    left: -1px;
  }

  :hover::before {
    width: 0;
    background: ${props => props.theme.isLight ? colors.lightGrey : colors.navy};
  }

  :hover::after {
    height: 0;
    background: ${props => props.theme.isLight ? colors.lightGrey : colors.navy};
  }

  :hover {
    background: ${props => props.theme.isLight ? colors.lightGrey : colors.navy};
  }

  :hover div {
    width: 0;
    background: ${props => props.theme.isLight ? colors.lightGrey : colors.navy};
    transition: .2s;
  }

  @media (min-width: ${breakpoints.md}) {
    font-size: ${props => props.large ? "22px" : "18px"};
    height: ${props => props.large ? "46px" : "38px"};
    padding: ${props => props.large ? "0 50px" : "0 40px"};
    border: ${props => props.large ? "2px" : "1px"} solid ${props => props.isred ? colors.red : props.theme.isLight ? colors.navy : colors.lightGrey};

    ::before {
      width: ${props => props.large ? "15px" : "10px"};
      height: ${props => props.large ? "2px" : "1px"};
      top: ${props => props.large ? "-2px" : "-1px"};
    }

    ::after {
      width: ${props => props.large ? "2px" : "1px"};
      height: ${props => props.large ? "15px" : "10px"};
      top: ${props => props.large ? "30px" : "20px"};
      left: ${props => props.large ? "-2px" : "-1px"};
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: ${props => props.large ? "24px" : "18px"};
    height: ${props => props.large ? "56px" : "38px"};
  };
`;

const After = styled.div`
  bottom: -1px;
  right: ${props => props.large ? "30px" : "20px"};
  height: 1px;
  width: ${props => props.large ? "15px" : "10px"};
  background: ${props => props.theme.isLight ? colors.lightGrey : colors.navy};
  transition: .2s;
  position: absolute;
  :hover {
    background: ${props => props.theme.isLight ? colors.lightGrey : colors.navy};
  }
  @media (min-width: ${breakpoints.md}) {
    bottom: ${props => props.large ? "-2px" : "-1px"};
    height: ${props => props.large ? "2px" : "1px"};
  }
`;

const Button = ({ to, children, isred, large }) => {
  let isExternal = true;

  if (to.startsWith("/")){
    isExternal = false;
  }

  return (
    <React.Fragment>
      { to ?
        <React.Fragment>
          {isExternal ?
            <ExternalLinkStyled href={to} isred={isred} large={large} aria-label={children}>
              {children}
              <After large={large}/>
            </ExternalLinkStyled>
            :
            <LinkStyled to={to} isred={isred} large={large} aria-label={children}>
              {children}
              <After large={large}/>
            </LinkStyled>
          }
        </React.Fragment>
      :
      <ButtonStyled isred={isred} large={large} aria-label={children}>
        {children}
        <After large={large}/>
      </ButtonStyled>
      }
    </React.Fragment>
  )
}

export default Button;
