import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
import MobileNav from "./MobileNav";
import { graphql, Link, StaticQuery } from "gatsby";
import ThemeContext  from "../../services/themes/themeContext";
import styled from "styled-components";
import colors from "../../styles/colors";
import LogoLight from "../../resources/images/ui/logo-light.svg";
import LogoDark from "../../resources/images/ui/logo-dark.svg";
import BurgerIconLight from "../../resources/images/ui/burger-icon-light.svg";
import BurgerIconDark from "../../resources/images/ui/burger-icon-dark.svg";
import CloseIconLight from "../../resources/images/ui/close-icon-light.svg";
import CloseIconDark from "../../resources/images/ui/close-icon-dark.svg";
import LightMode from "../../resources/images/ui/light-mode-icon.svg";
import DarkMode from "../../resources/images/ui/dark-mode-icon.svg";
import breakpoints from "../../styles/breakpoints";
import Button from "../Core/Button";
import { isBrowser } from "../../services/browser/browser";

const HeaderContainer = styled.div`
  background: ${props => props.navBackground ? (props.isLight ? colors.lightGrey : colors.darkGrey) :'transparent' };
  width: 100%;
  z-index: 999;
  position: fixed;
  top: ${props => props.isHeaderVisible ? '0' : '-68px'};
  transition: 0.4s;
  
  @media(min-width: ${breakpoints.md}){
    top: ${props => props.isHeaderVisible ? '0' : '-85px'};
  }
`;

const HeaderBlock = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  
  @media(min-width: 1440px){
    padding-left: calc((100vw - 1440px)/2);
  }
  
  @media(min-width: 1560px){
    padding-left: 56px;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  
  @media(min-width: ${breakpoints.md}){
    padding: 22px 24px;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ModeButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  height: 36px;
  width: 22px;
  margin: 0 24px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    height: ${({ theme }) => theme.isLight ? "18px" : "22px"};
    width: ${({ theme }) => theme.isLight ? "16px" : "22px"};
  }
  
  @media(min-width: ${breakpoints.md}){
    margin: 0 0 0 24px;
    
    img {
      height: ${({ theme }) => theme.isLight ? "24px" : "28px"};
      width: ${({ theme }) => theme.isLight ? "22px" : "28px"};
    }
  }
`;

const BurgerButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  height: 36px;
  width: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  
  @media(min-width: ${breakpoints.md}){
    display: none;
  }
`;

const Links = styled.div`
  display: none;
  
  @media(min-width: ${breakpoints.md}){
    display: flex;
    flex-direction: row;
    margin-right: -12px;
  }
`;

const ButtonContainer = styled.div`
  margin: 0 16px;
  
  @media(min-width: ${breakpoints.lg}){
    margin: 0 24px;
  }
`;

const MOBILE_NAV_HEIGHT = 68;
const DESKTOP_NAV_HEIGHT = 85;

const Header = () => {
  const prevScrollPos = useRef(0);
  const [visible, setVisible] = useState(true);
  const {isLight, toggleTheme} = useContext(ThemeContext);
  const [navHeight, setNavHeight] = useState(MOBILE_NAV_HEIGHT);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [navBackground, setNavBackground] = useState(false);

  const handleScroll = useCallback(() => {
    if (isBrowser()) {
      const { scrollY } = window;
      const scrollSensitivity = 20;

      if (prevScrollPos.current > (scrollY + scrollSensitivity) || (scrollY < navHeight)){
        if (visible !== true) {
          setVisible(true)
        }
      } else if (prevScrollPos.current < (scrollY - scrollSensitivity) && scrollY > navHeight){
        if (visible !== false) {
          setVisible(false)
        }
      }

      if (prevScrollPos.current > (scrollY + scrollSensitivity) || prevScrollPos.current < (scrollY - scrollSensitivity)) {
        prevScrollPos.current = scrollY;
      }

      if (!navBackground) {
        if (scrollY > navHeight+100) {
          setNavBackground(true);
        }
      }

      if (navBackground) {
       if (scrollY < navHeight+100) {
          setNavBackground(false);
        }
      }
    }
  }, [visible, navHeight, navBackground]);

  useEffect(() => {
    const onResize = () => {
      const isDesktop = window.innerWidth >= 992
      const newNavHeight = isDesktop ? DESKTOP_NAV_HEIGHT : MOBILE_NAV_HEIGHT;

      if (!navHeight || (navHeight !== newNavHeight)) {
        setNavHeight(newNavHeight);
      }
    }
    if (isBrowser()) {
      onResize();
      window.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", onResize);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [navHeight, handleScroll])

  return (
    <StaticQuery
      query={graphql`
      query {
        header: contentfulHeader {
          links {
            ctaUrl
            ctaLabel
          }
        }
      }
    `}
      render={({ header }) => {
        const links = header.links;
        return (
          <React.Fragment>
            <HeaderContainer isHeaderVisible={visible} navBackground={navBackground} isLight={isLight}>
              <HeaderBlock>
                <HeaderContent>
                  <Link to={"/"}>
                    <img src={isLight ? LogoDark : LogoLight} alt="L4G" />
                  </Link>
                  <LinksContainer>
                    <Links>
                      {links.map((link, index) => {
                        return (
                          <ButtonContainer key={index}>
                            <Button to={link.ctaUrl !== null ? link.ctaUrl : "/"}>
                              {link.ctaLabel}
                            </Button>
                          </ButtonContainer>
                        )
                      })}
                    </Links>
                    <ModeButton onClick={toggleTheme}>
                      <img src={isLight ? DarkMode : LightMode} alt="Mode icon" />
                    </ModeButton>
                    <BurgerButton aria-label="Open mobile menu" onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}>
                      <img src={isMobileNavOpen ? (isLight ? CloseIconDark : CloseIconLight) : (isLight ? BurgerIconDark : BurgerIconLight)} alt="Menu" />
                    </BurgerButton>
                  </LinksContainer>
                </HeaderContent>
              </HeaderBlock>
            </HeaderContainer>
            <MobileNav links={links} isMobileNavOpen={isMobileNavOpen} setIsMobileNavOpen={setIsMobileNavOpen}/>
          </React.Fragment>
        )
      }}
    />
  )
}

export default Header
