import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { ThemeProvider } from "styled-components"
import { GridThemeProvider } from "styled-bootstrap-grid"
import { lightTheme, darkTheme } from "../../styles/themes"
import GlobalStyle from "../../styles/global"
import ThemeContext, { useDarkMode } from "../../services/themes/themeContext"
import Header from "./Header"
import Footer from "./Footer"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Loader from "../Core/Loader"
import { isBrowser } from "../../services/browser/browser"
import { getItem, setItem } from "../../services/browser/localStorage"
import Fade from "react-reveal/Fade"
import CookieBanner from "../Core/CookieBanner"

const GlobalContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

const PageContainer = styled.div`
  width: 100%;
  flex-grow: 1;
`

const gridTheme = {
  breakpoints: {
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575
  },
  row: {
    padding: 0
  },
  col: {
    padding: 0
  },
  container: {
    padding: 0,
    maxWidth: {
      xl: 1410,
      lg: 1410,
      md: 1410,
      sm: 1410,
      xs: 1410
    }
  }
}

const LOADER_STATUS = "loader_status"

const Layout = ({ children }) => {
  const [theme, toggleTheme] = useDarkMode()
  const [isLoaderActive, setLoaderActive] = useState(getItem(LOADER_STATUS) !== "1")
  useEffect(() => {
    if (!isBrowser()) {
      return
    }

    if (isLoaderActive) {
      setTimeout(() => {
        setItem(LOADER_STATUS, "1")
        setLoaderActive(false)
      }, 2000)
    }
  }, [isLoaderActive])

  return (
    <React.Fragment>
      <ThemeContext.Provider value={{ theme, toggleTheme, isLight: theme === "light" }}>
        <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
          <GridThemeProvider gridTheme={gridTheme}>
            <GlobalStyle theme={theme === "light" ? lightTheme : darkTheme} />
            <Fade when={isLoaderActive} duration={300}>
              <Loader isLight={theme === "light"} />
            </Fade>
            {!isLoaderActive &&
            <GlobalContainer>
              <Header />
              <CookieBanner/>
              <PageContainer>
                {children}
              </PageContainer>
              <Footer />
            </GlobalContainer>
            }
          </GridThemeProvider>
        </ThemeProvider>
      </ThemeContext.Provider>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
