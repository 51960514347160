import React from "react";
import { createGlobalStyle } from "styled-components";
import { reset } from "styled-reset";
import fonts from "./fonts";
import colors from "./colors"

const StyledGlobalStyle = createGlobalStyle`
    body {
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.48px;
      ${fonts.CabinRegular};
      background: ${props => props.theme.isLight ? colors.lightGrey : colors.darkGrey};
    }
    .react-reveal{
      animation-fill-mode: backwards !important;
    }
    ${reset}
`;

const GlobalStyle = () => (
  <>
    <StyledGlobalStyle />
  </>
);

export default GlobalStyle;
