import React, { useContext, useEffect, useState } from "react"
import { isBrowser } from "../../services/browser/browser";
import styled from 'styled-components';
import colors from "../../styles/colors";
import ThemeContext from "../../services/themes/themeContext";
import DarkCross from "../../resources/images/ui/close-icon-dark.svg";
import LightCross from "../../resources/images/ui/close-icon-light.svg";
import fonts from "../../styles/fonts"

const Banner = styled.div`
  display: ${props => props.isActive ? 'block' : 'none'};
  background: ${({ theme }) => theme.isLight ? colors.grey : colors.mediumNavy};
  border: 2px solid ${({ theme }) => theme.isLight ? colors.grey : colors.mediumNavy};;
  color: ${({ theme }) => theme.isLight ? colors.darkGrey : colors.white};
  padding: 8px 40px 10px 10px;
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 100;
  font-size: 14px;
  line-height: 16px;
  max-width: 450px;
  border-radius: 4px;
  margin-left: 16px;
  ${fonts.RalewayMedium}
`;

const Button = styled.button`
  position: absolute;
  right: 12px;
  background: transparent;
  outline: none;
  border: none;
  top: calc(50% - 8px);
  height: 16px;
  width: 16px;
  padding: 0;
  cursor: pointer;
  
  img {
    height: 100%;
    width: 100%;
  }
`;

const CookieBanner = () => {
  const [isActive, setActive] = useState(true);
  const {isLight} = useContext(ThemeContext);

  useEffect(() => {
    if (isBrowser()){
      if (localStorage.getItem('policyState') === 'accepted') {
        setActive(false);
      }
    }
  }, [])

  const hideAlert = () => {
    setActive(false);
    localStorage.setItem('policyState', 'accepted');
  };

  return (
    <Banner isActive={isActive} aria-label="Accept cookies">
      This site uses cookies to provide you with a better user experience. By using L4G you accept our use of cookies.
      <Button onClick={hideAlert} ari>
        <img src={isLight ? DarkCross : LightCross} alt="Close"/>
      </Button>
    </Banner>
  )
}

export default CookieBanner