import React from "react";
import styled from 'styled-components';

const ContainerStyled = styled.div`
  max-width: 1392px;
  width: calc(100% - 48px);
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
`;

const ContentContainer = ({ children }) => {
  return (
    <ContainerStyled>
      {children}
    </ContainerStyled>
  )
}

export default ContentContainer